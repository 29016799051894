import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link, Span, Icon, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію | Juna & Glate
			</title>
			<meta name={"description"} content={"Juna & Glate - це не просто назва, це почуття, емоції та втілення вільного духу, який живе в серці кожного мотоцикліста. Це організація, яка об'єднує ентузіастів, учнів та досвідчених гонщиків під одним дахом, обіцяючи мотоциклетний досвід, який відрізняється від звичайного."} />
			<meta property={"og:title"} content={"Про компанію | Juna & Glate"} />
			<meta property={"og:description"} content={"Juna & Glate - це не просто назва, це почуття, емоції та втілення вільного духу, який живе в серці кожного мотоцикліста. Це організація, яка об'єднує ентузіастів, учнів та досвідчених гонщиків під одним дахом, обіцяючи мотоциклетний досвід, який відрізняється від звичайного."} />
			<meta property={"og:image"} content={"https://junaandglate.com/pics/contact.jpg"} />
			<link rel={"shortcut icon"} href={"https://junaandglate.com/pics/1036184.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://junaandglate.com/pics/1036184.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://junaandglate.com/pics/about.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="360px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Про нас
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Про Juna& Glate
				</Text>
				<Text font="--base">
					Juna & Glate - це не просто назва, це почуття, емоції та втілення вільного духу, який живе в серці кожного мотоцикліста. Це організація, яка об'єднує ентузіастів, учнів та досвідчених гонщиків під одним дахом, обіцяючи мотоциклетний досвід, який відрізняється від звичайного.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 50px 0px 0px"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наша філософія вибору мотоциклів
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ми віримо в якість, а не в кількість. Кожен мотоцикл в нашій колекції ретельно відібраний, щоб забезпечити різноманітні враження для наших клієнтів. Від мотоциклів, що ревуть від потужності, до тих, що пропонують більш розслаблену їзду містом, наша колекція підібрана так, щоб задовольнити бажання кожного мотоцикліста. Кожен мотоцикл - це не просто засіб пересування, це пригода, яка чекає, щоб її дослідити.
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="#3333c5"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Section padding="120px 0 120px 0" lg-margin="0 0 0 0" lg-padding="80px 0 80px 0" quarkly-title="Price-10">
			<Text
				margin="0px 0px 20px 0px"
				font="normal 600 46px/1.2 --fontFamily-sans"
				text-align="center"
				md-margin="0px 0px 50px 0px"
				width="700p"
				align-self="center"
				color="--darkL1"
				sm-margin="0px 0px 30px 0px"
			>
				Школа Juna& Glate:{" "}
				<br />
				Виховуємо вправних гонщиків
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 300 20px/1.5 --fontFamily-sansTrebuchet"
				width="700px"
				align-self="center"
				md-width="100%"
				color="#80868a"
				md-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
			>
				Їзда на мотоциклі - це не просто вміння тиснути на газ і кермувати, це мистецтво. І як будь-яке мистецтво, воно вимагає відданості, терпіння та правильного керівництва. У школі Juna& Glate
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-margin="0px 0 0px 0"
				md-flex-direction="column"
				border-width="1px"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="36px 36px 26px 36px"
					border-color="#dadbe2"
					display="flex"
					flex-direction="column"
					position="relative"
					z-index="2"
					width="33%"
					lg-width="100%"
					lg-padding="30px 30px 30px 30px"
					lg-align-items="stretch"
					sm-padding="30px 15px 40px 15px"
					font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
					md-border-width="1px"
					md-padding="40px 30px 40px 30px"
					md-align-items="stretch"
					justify-content="space-between"
					border-width="1px 0 1px 1px"
					border-style="solid"
					margin="40px 0px 0px 0px"
					border-radius="25px 0 0 25px"
					lg-margin="0 0px 40px 0px"
					lg-border-radius="25px"
					lg-border-width="1px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						margin="0px 0px 35px 0px"
					>
						<Text margin="0px 0px 25px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							Пробний Урок
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							<Span
								font="normal 700 46px/1.2 --fontFamily-sans"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								color="--darkL1"
							>
								₴ 400
							</Span>
							/ заняття
						</Text>
						<Box min-width="100px" min-height="100px">
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#bec3ca"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									базова теорія
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#bec3ca"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									екіпірування та захист
								</Text>
							</Box>
							<Box min-width="10px" min-height="10px" display="flex" align-items="center">
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									1 година практики з інструктором
								</Text>
							</Box>
						</Box>
					</Box>
					<Button
						color="#543ae7"
						border-color="#543ae7"
						border-radius="4px"
						font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
						margin="0px 0px 35px 0px"
						sm-margin="0px 0px 15px 0px"
						background="rgba(84, 58, 231, 0)"
						border-width="1px"
						border-style="solid"
						type="link"
						href="/contact-us"
						text-decoration-line="initial"
						text-align="center"
					>
						Забронювати урок
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="36px 36px 26px 36px"
					border-color="#dadbe2"
					display="flex"
					flex-direction="column"
					position="relative"
					z-index="1"
					width="33%"
					lg-width="100%"
					lg-padding="30px 30px 30px 30px"
					lg-align-items="stretch"
					md-padding="40px 30px 0 30px"
					md-align-items="stretch"
					sm-padding="30px 15px 30px 15px"
					justify-content="space-between"
					border-width="1px"
					border-style="solid"
					border-radius="25px 25px 0 0"
					lg-margin="0px 0px 40px 0px"
					lg-border-radius="25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						margin="0px 0px 35px 0px"
					>
						<Box
							min-width="10px0"
							min-height={0}
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 15px 0px"
						/>
						<Text margin="0px 0px 25px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							Курс для отримання прав
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							<Span
								font="normal 700 46px/1.2 --fontFamily-sans"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								color="--darkL1"
							>
								₴ 30 000
							</Span>
						</Text>
						<Box min-width="100px" min-height="100px">
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#393341"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									Повний курс теорії екстримального кермування
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#393341"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									30 індивідуальних практичних занять з інструктором
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0 0 8px 0"
								border-color="#bec3ca"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									Забезпечення байком та екіпіруванням на весь час курсу
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#bec3ca"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									Підготовка до екзамену в автошколі
								</Text>
							</Box>
						</Box>
					</Box>
					<Button
						background="#543ae7"
						color="--light"
						border-color="--color-indigo"
						border-radius="4px"
						font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
						margin="0px 0px 35px 0px"
						type="link"
						href="/contact-us"
						text-decoration-line="initial"
						text-align="center"
					>
						Придбати
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="36px 36px 26px 36px"
					border-color="#dadbe2"
					display="flex"
					flex-direction="column"
					position="relative"
					z-index="1"
					width="33%"
					lg-width="100%"
					lg-padding="30px 30px 30px 30px"
					lg-align-items="stretch"
					sm-padding="30px 15px 30px 15px"
					md-padding="40px 30px 40px 30px"
					md-align-items="stretch"
					justify-content="space-between"
					border-width="1px 1px 1px 0"
					border-style="solid"
					margin="40px 0px 0px 0px"
					border-radius="0 25px 25px 0"
					lg-margin="0 0px 0px 0px"
					lg-border-radius="25px"
					lg-border-width="1px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						lg-align-items="stretch"
						margin="0px 0px 35px 0px"
					>
						<Text margin="0px 0px 25px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							Урок екстремальної їзди
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 17px/1.5 --fontFamily-sansTrebuchet" color="#80868a">
							<Span
								font="normal 700 46px/1.2 --fontFamily-sans"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								color="--darkL1"
							>
								₴ 1500
							</Span>
							/ заняття
						</Text>
						<Box min-width="100px" min-height="100px">
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#393341"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									досвідчений інструктор відповідно до ваших потреб роскаже і покаже як виконувати ті чи інші фігури
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								border-color="#393341"
								margin="0 0 8px 0"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									2 години практики виконання
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0 0 8px 0"
								border-color="#bec3ca"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									спеціально підготований беспечний майданчик
								</Text>
							</Box>
							<Box
								min-width="10px"
								min-height="10px"
								display="flex"
								align-items="center"
								margin="0 0 8px 0"
								border-color="#bec3ca"
							>
								<Icon
									category="io"
									icon={IoIosCheckmarkCircle}
									size="25px"
									color="#543ae7"
									margin="0px 8px 0px 0px"
								/>
								<Text margin="0px 0px 0px 0px" font="normal 300 17px/1.2 --fontFamily-sansTrebuchet" color="#80868a">
									підвісні системи страхування
								</Text>
							</Box>
						</Box>
					</Box>
					<Button
						color="#543ae7"
						border-color="#543ae7"
						border-radius="4px"
						font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
						margin="0px 0px 35px 0px"
						sm-margin="0px 0px 15px 0px"
						background="rgba(84, 58, 231, 0)"
						border-width="1px"
						border-style="solid"
						type="link"
						href="/contact-us"
						text-decoration-line="initial"
						text-align="center"
					>
						Забронювати урок
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});